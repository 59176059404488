<template>
  <div class="relative">
    <header class="flex items-center justify-center space-x-6" />
    <main>
      <router-view />
    </main>
  </div>
</template>

<script lang="ts" setup></script>
